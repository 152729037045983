import { Box,Link } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { NavLink, useLocation } from "react-router-dom";
import { useEffect, useState } from 'react'

import './menu.scss';
import Dashboard from '../../assets/images/menu/dashboard.png'
import Account from '../../assets/images/menu/account.png'
import Calculator from '../../assets/images/menu/calculator.png'
import Sweep from '../../assets/images/menu/sweep.png'
import Swap from '../../assets/images/menu/swap.png'
import Chart from '../../assets/images/menu/chart.png'
import Dashboard_active from '../../assets/images/menu/dashboard-active.png'
import Account_active from '../../assets/images/menu/account-active.png'
import Calculator_active from '../../assets/images/menu/calculator-active.png'
import Sweep_active from '../../assets/images/menu/sweep-active.png'
import Swap_active from '../../assets/images/menu/swap-active.png'
import Chart_active from '../../assets/images/menu/chart-active.png'

export default function Menu() {

    const isSmallScreen = useMediaQuery("(max-width: 1020px)");
    const location = useLocation()
    const [pathname, setPathname] = useState(location.pathname)
    
    useEffect(() => {
        setPathname(location.pathname)
      }, [location]);

    const menuList = [
        {
            title: 'Swap',
            icon: Swap,
            iconActive: Swap_active,
            url: 'https://app.uniswap.org/#/swap?chain=mainnet&inputCurrency=ETH&outputCurrency=0xb694ec8cAc69dcA530aD0F2A3Ba0903c5b8aeA4a'
        },
        {
            title: 'Chart',
            icon: Chart,
            iconActive: Chart_active,
            url: 'https://dexscreener.com/ethereum/0x8058f555c28ae12e67d43ee6feff8987f53f1a44'
        }
    ]

    return (
        <div className='menu-view'>
            <Box display='flex' className="menuList font-16">
                <Link component={NavLink} to="/" className={ `menuItem ${pathname=="/" ? "menuItemActive" : ""}`}>
                    <Box display='flex' alignItems='center' justifyContent='center' className="menuItemContainer">
                        <img src={pathname=="/" ? Dashboard_active : Dashboard} />
                        <div className="color6 ml-8">Dashboard</div>
                    </Box>
                </Link>
                <Link component={NavLink} to="/account" className={ `menuItem ${pathname=="/account" ? "menuItemActive" : ""}`}>
                    <Box display='flex' alignItems='center' justifyContent='center' className="menuItemContainer">
                        <img src={pathname=="/account" ? Account_active : Account} />
                        <div className="color6 ml-8">Account</div>
                    </Box>
                </Link>
                <Link component={NavLink} to="/calculator" className={ `menuItem ${pathname=="/calculator" ? "menuItemActive" : ""}`}>
                    <Box display='flex' alignItems='center' justifyContent='center' className="menuItemContainer">
                        <img src={pathname=="/calculator" ? Calculator_active : Calculator} />
                        <div className="color6 ml-8">Calculator</div>
                    </Box>
                </Link>
                <Link component={NavLink} to="/sweep" className={  `menuItem ${pathname=="/sweep" ? "menuItemActive" : ""}`}>
                    <Box display='flex' alignItems='center' justifyContent='center' className="menuItemContainer">
                        <img src={pathname=="/sweep" ? Sweep_active : Sweep} />
                        <div className="color6 ml-8">Sweep</div>
                    </Box>
                </Link>
                {
                    menuList.map((item, index) =>
                        <Link href={item.url} target='_blank' className="menuItem">
                            <Box display='flex' alignItems='center' justifyContent='center' className="menuItemContainer" key={index}>
                                <img src={item.icon} />
                                <div className="color6 ml-8">{item.title}</div>
                            </Box>
                        </Link>
                    )
                }
            </Box>
        </div>
    )
}