import FuturaBoldWOFF from "../assets/fonts/Futura-Bold.woff";
import FuturaMediumWOFF from "../assets/fonts/Futura-Medium.woff";
import FuturaMediumItalicWOFF from "../assets/fonts/Futura-Medium-Italic.woff";

const futuraBold = {
  fontFamily: "Futura-Bold",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
		local('FuturaBold'),
		local('Futura-Bold'),
		url(${FuturaBoldWOFF}) format('woff')
	`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};
const futuraMedium = {
  fontFamily: "Futura-Medium",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
		local('FuturaMedium'),
		local('Futura-Medium'),
		url(${FuturaMediumWOFF}) format('woff')
	`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};
const futuraMediumItalic = {
  fontFamily: "Futura-Medium-Italic",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
		local('FuturaMediumItalic'),
		local('Futura-Medium-Italic'),
		url(${FuturaMediumItalicWOFF}) format('woff')
	`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const fonts = [futuraBold,futuraMedium,futuraMediumItalic];

export default fonts;
