import { ThemeProvider } from "@material-ui/core/styles";
import { useEffect, useState, useCallback, lazy, Suspense } from "react";
import { Route, Redirect, Switch, useLocation } from "react-router-dom";
import { Hidden, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import useTheme from "./hooks/useTheme";
import { useWeb3Context } from "./hooks/web3Context";
import { storeQueryParameters } from "./helpers/QueryParameterHelper";

import { light as lightTheme } from "./themes/light.js";

import "./style.scss";
import Header from './components/Header'
import Footer from './components/Footer'
import { loadAppDetails } from "./slices/AppSlice";
import { getBalances } from "./slices/AccountSlice";
import { useDispatch } from "react-redux";
import Messages from "./components/Messages/Messages";
import LoadingSplash from "./components/LoadingSplash";

const DEBUG = false;
const Home = lazy(() => import('./views/home'));
const Calculator = lazy(() => import('./views/calculator'));
const Account = lazy(() => import('./views/account'));
const Sweep = lazy(() => import('./views/sweep'));

if (DEBUG) console.log(" Connecting to Mainnet Ethereum");

// const blockExplorer = targetNetwork.blockExplorer;

function App() {
  const [theme, mounted] = useTheme();
  const location = useLocation();
  const { connect, connected, address, chainID, provider, hasCachedProvider } = useWeb3Context();
  const [walletChecked, setWalletChecked] = useState(false);
  const dispatch = useDispatch();
  const [pathname, setPathname] = useState(location.pathname)

  async function loadDetails(whichDetails) {
    let loadProvider = provider;
    if (whichDetails === "app") {
      loadApp(loadProvider);
    }

    // don't run unless provider is a Wallet...
    if (whichDetails === "account" && address && connected) {
      loadAccount(loadProvider);
    }
  }

  const loadApp = useCallback(
    loadProvider => {
      dispatch(loadAppDetails({ networkID: chainID, provider: loadProvider }))
    },
    [connected],
  );

  const loadAccount = useCallback(
    loadProvider => {
      dispatch(getBalances({ networkID: chainID, address, provider: loadProvider }))
    },
    [connected],
  );
  useEffect(() => {
    // don't load ANY details until wallet is Checked
    if (walletChecked) {
      loadDetails("app");
    }
  }, [walletChecked]);

  useEffect(() => {
    // don't load ANY details until wallet is Connected
    if (connected) {
      loadDetails("account");
    }
  }, [connected]);

  useEffect(() => {
    if (hasCachedProvider()) {
      // then user DOES have a wallet
      connect().then(() => {
        setWalletChecked(true);
      });
    } else {
      // then user DOES NOT have a wallet
      setWalletChecked(true);
    }

    // We want to ensure that we are storing the UTM parameters for later, even if the user follows links
    storeQueryParameters();
  }, []);

  useEffect(() => {
    setPathname(location.pathname)
  }, [location]);

  let themeMode = lightTheme;

  return (
    <ThemeProvider theme={themeMode}>
      <Messages />
      <CssBaseline />
      <Suspense fallback={<LoadingSplash />}>
        <div className='app-container'>
          <Header />
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/calculator">
              <Calculator />
            </Route>
            <Route exact path="/account">
              <Account />
            </Route>
            <Route exact path="/sweep">
              <Sweep />
            </Route>
            <Route exact path="/">
              <Redirect to="/" />
            </Route>
          </Switch>
          <Footer />
        </div>
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
