import { ethers } from "ethers";
import { addresses } from "../constants";
import { setAll } from "../helpers";
import { createSlice, createSelector, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { IBaseAsyncThunk } from "./interfaces";
import { abi as ticketAbi } from "../abi/Ticket.json";
import { abi as pairAbi } from "../abi/Pair.json";
import { abi as IERC20Abi } from "../abi/IERC20.json";

const initialState = {
    loading: false,
    loadingMarketPrice: false,
};

export const loadAppDetails = createAsyncThunk(
    "app/loadAppDetails",
    async ({ networkID, provider }: IBaseAsyncThunk, { dispatch }) => {
        const ticketContract = new ethers.Contract(addresses[networkID].TICKET as string, ticketAbi, provider)
        const ticketDecimals = await ticketContract.decimals()
        const totalSupply = await ticketContract.totalSupply()
        const circulatingSupply = await ticketContract.getCirculatingSupply()
        const lotteryPoolValue = await ticketContract.winAmount()
        const wethContract = new ethers.Contract(addresses[networkID].WETH as string, IERC20Abi, provider)
        const wethDecimals = await wethContract.decimals()

        const response = await fetch("https://api.coinbase.com/v2/prices/ETH-USD/spot");
        const info = await response.json()

        const pairContract = new ethers.Contract(addresses[networkID].TICKET_WETH_LP as string, pairAbi, provider)
        const reserves = await pairContract.getReserves();
        let marketPrice;
        let poolAmount;
        const token0 = await pairContract.token0();
        if (token0.toString().toLowerCase() == addresses[networkID].WETH.toLowerCase()) {
            marketPrice = wethDecimals > ticketDecimals ? reserves[0] / reserves[1] / Math.pow(10, wethDecimals - ticketDecimals) : reserves[0] / reserves[1] * Math.pow(10, ticketDecimals - wethDecimals);
            poolAmount = reserves[0]
        } else {
            marketPrice = wethDecimals > ticketDecimals ? reserves[1] / reserves[0] / Math.pow(10, wethDecimals - ticketDecimals) : reserves[1] / reserves[0] * Math.pow(10, ticketDecimals - wethDecimals);;
            poolAmount = reserves[1]
        }

        const treasuryReceiverAddress = await ticketContract.treasuryReceiver()
        const treasuryEthBalance = await provider.getBalance(treasuryReceiverAddress)

        const ticketInsuranceFundReceiverAddress = await ticketContract.ticketInsuranceFundReceiver()
        const ticketEthBalance = await provider.getBalance(ticketInsuranceFundReceiverAddress)

        const infinityPoolAddress = await ticketContract.infinityPool()
        const infinityPoolTicketVal = await ticketContract.balanceOf(infinityPoolAddress)
        return {
            ticketPrice: info.data.amount * marketPrice,
            totalSupply: ethers.utils.formatUnits(totalSupply, ticketDecimals),
            circulatingSupply: ethers.utils.formatUnits(circulatingSupply, ticketDecimals),
            lotteryPoolEthValue: ethers.utils.formatUnits(lotteryPoolValue, 18),
            ethPrice: info.data.amount * 1,
            poolValue: info.data.amount * 2 * Number(ethers.utils.formatUnits(poolAmount, wethDecimals)),
            treasuryReceiverAsset: info.data.amount * Number(ethers.utils.formatUnits(treasuryEthBalance, 18)),
            ticketInsuranceFundVal: info.data.amount * Number(ethers.utils.formatUnits(ticketEthBalance, 18)),
            infinityPoolTicketVal: ethers.utils.formatUnits(infinityPoolTicketVal, ticketDecimals),
        };
    },
);

const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        fetchAppSuccess(state, action) {
            setAll(state, action.payload);
        },
    },
    extraReducers: builder => {
        builder
            .addCase(loadAppDetails.pending, state => {
                state.loading = true;
            })
            .addCase(loadAppDetails.fulfilled, (state, action) => {
                setAll(state, action.payload);
                state.loading = false;
            })
            .addCase(loadAppDetails.rejected, (state, { error }) => {
                state.loading = false;
            })
    }
});

const baseInfo = (state: RootState) => state.app;

export default appSlice.reducer;

export const { fetchAppSuccess } = appSlice.actions;

export const getAppState = createSelector(baseInfo, app => app);
