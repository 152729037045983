import { ethers } from "ethers";
import { addresses } from "../constants";
import { abi as ierc20Abi } from "../abi/IERC20.json";
import { setAll } from "../helpers";

import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { IBaseAddressAsyncThunk } from "./interfaces";

export const getBalances = createAsyncThunk(
    "account/getBalances",
    async ({ address, networkID, provider }: IBaseAddressAsyncThunk) => {

        const tokenContract = new ethers.Contract(addresses[networkID].TICKET as string, ierc20Abi, provider)
        const ticketDecimals = await tokenContract.decimals()
        const balances_ticket = await tokenContract.balanceOf(address)

        const ethBalance = await provider.getBalance(address)
        
        return {
            balances_ticket: ethers.utils.formatUnits(balances_ticket, ticketDecimals),
            ethBalance: ethers.utils.formatUnits(ethBalance, 18),
        };
    },
);

interface IAccountSlice {
    loading: boolean;
}

const initialState: IAccountSlice = {
    loading: false,
};

const accountSlice = createSlice({
    name: "account",
    initialState,
    reducers: {
        fetchAccountSuccess(state, action) {
            setAll(state, action.payload);
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getBalances.pending, state => {
                state.loading = true;
            })
            .addCase(getBalances.fulfilled, (state, action) => {
                setAll(state, action.payload);
                state.loading = false;
            })
            .addCase(getBalances.rejected, (state, { error }) => {
                state.loading = false;
            })
    },
});

export default accountSlice.reducer;

export const { fetchAccountSuccess } = accountSlice.actions;

const baseInfo = (state: RootState) => state.account;

export const getAccountState = createSelector(baseInfo, account => account);
