

interface IAddresses {
  [key: number]: { [key: string]: string };
}

export const addresses: IAddresses = {
  1: {
    TICKET: '0xb694ec8cAc69dcA530aD0F2A3Ba0903c5b8aeA4a',
    WETH: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    TICKET_WETH_LP: '0x8058F555C28ae12e67d43ee6FeFF8987F53F1A44',
  },
  4: {
    TICKET: '0xFC2Ba008aE57342f066BdeC7c48B584d068152d5',
    WETH: '0xc778417E063141139Fce010982780140Aa0cD5Ab',
    TICKET_WETH_LP: '0x91ACBb8720a6Fe13fec871DdAd9E8F4584394D32',
  },
};



