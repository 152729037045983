import { Box, Dialog, Link } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useState, useEffect } from 'react'

import './header.scss';
import '../Menu/menu.scss'
import Logo from '../../assets/images/logo.png'
import MenuIcon from '../../assets/images/menu.png'
import Close from '../../assets/images/close.png'
import Dashboard from '../../assets/images/menu/dashboard.png'
import Account from '../../assets/images/menu/account.png'
import Calculator from '../../assets/images/menu/calculator.png'
import Sweep from '../../assets/images/menu/sweep.png'
import Swap from '../../assets/images/menu/swap.png'
import Chart from '../../assets/images/menu/chart.png'
import Dashboard_active from '../../assets/images/menu/dashboard-active.png'
import Account_active from '../../assets/images/menu/account-active.png'
import Calculator_active from '../../assets/images/menu/calculator-active.png'
import Sweep_active from '../../assets/images/menu/sweep-active.png'
import Swap_active from '../../assets/images/menu/swap-active.png'
import Chart_active from '../../assets/images/menu/chart-active.png'
import { useWeb3Context } from "../../hooks/web3Context";
import { shorten } from '../../helpers'
import { NavLink, useLocation } from "react-router-dom";
import Menu from "../Menu";

export default function Header() {

    const isSmallScreen = useMediaQuery("(max-width: 1020px)");
    const [openMenu, setOpenMenu] = useState(false);
    const { connect, disconnect, connected, web3, address } = useWeb3Context();
    const [isConnected, setConnected] = useState(connected);

    let buttonText = "Connect Wallet";
    let clickFunc = connect;

    const location = useLocation()
    const [pathname, setPathname] = useState(location.pathname)

    useEffect(() => {
        setPathname(location.pathname)
    }, [location]);

    const menuList = [
        {
            title: 'Swap',
            icon: Swap,
            iconActive: Swap_active,
            url: 'https://app.uniswap.org/#/swap?chain=mainnet&inputCurrency=ETH&outputCurrency=0xb694ec8cAc69dcA530aD0F2A3Ba0903c5b8aeA4a'
        },
        {
            title: 'Chart',
            icon: Chart,
            iconActive: Chart_active,
            url: 'https://dexscreener.com/ethereum/0x8058f555c28ae12e67d43ee6feff8987f53f1a44'
        }
    ]

    if (isConnected) {
        buttonText = shorten(address); // "Disconnect";
        clickFunc = disconnect;
    }

    useEffect(() => {
        setConnected(connected);
    }, [web3, connected]);

    const handleCloseMenu = () => {
        setOpenMenu(false);
    };
    if (isSmallScreen && !isConnected) {
        buttonText = 'Connect'
    }
    return (
        <div className='header-view'>
            <Box display='flex' justifyContent='center'>
                <div className="container">
                    {
                        isSmallScreen ?
                            <Box display='flex' justifyContent='space-between' alignItems='center' className="header-m font-14">
                                <img src={MenuIcon} className="" onClick={() => { setOpenMenu(true) }} />
                                <Box display='flex' alignItems='center' className="header-r">
                                    <img className="logo" src={Logo} />
                                    <Box display='flex' justifyContent='center' alignItems='center' className="enterApp ml-18 color1 font-family-Futura-Bold border-radius-4"
                                        onClick={clickFunc} >{buttonText}</Box>
                                </Box>
                            </Box>
                            :
                            <Box display='flex' justifyContent='space-between' alignItems='center' className="header font-16">
                                <img className="" src={Logo} />
                                <Box display='flex' className="menuNavList">
                                    <a href="https://www.ticketinu.com/" target='_blank' className="menuItem">
                                        <div className="color2 text-center">Home</div>
                                    </a>
                                    <a href="https://docs.ticketinu.com/" target='_blank' className="menuItem">
                                        <div className="color2 text-center">Docs</div>
                                    </a>
                                    <a href="https://discord.gg/GHN7ESkXxU" target='_blank' className="menuItem">
                                        <div className="color2 text-center">Discord</div>
                                    </a>
                                </Box>
                                <Box display='flex' justifyContent='center' alignItems='center' className="enterApp text-center color1 font-family-Futura-Bold border-radius-4"
                                    onClick={clickFunc} >{buttonText}</Box>
                            </Box>
                    }
                </div>
            </Box>
            <Dialog
                fullScreen
                open={openMenu}
                onClose={handleCloseMenu} >
                <div className='menu-container font-16'>
                    <Box display='flex' flexDirection='column' alignItems='center'>
                        <img src={Logo} className="mt-18" />

                        <Box display='flex' className="menuList font-16 mt-32">
                            <Link component={NavLink} to="/" className={`menuItem ${pathname == "/" ? "menuItemActive" : ""}`} onClick={handleCloseMenu}>
                                <Box display='flex' alignItems='center' justifyContent='center' className="menuItemContainer">
                                    <img src={pathname == "/" ? Dashboard_active : Dashboard} />
                                    <div className="color6 ml-8">Dashboard</div>
                                </Box>
                            </Link>
                            <Link component={NavLink} to="/account" className={`menuItem ${pathname == "/account" ? "menuItemActive" : ""}`} onClick={handleCloseMenu}>
                                <Box display='flex' alignItems='center' justifyContent='center' className="menuItemContainer">
                                    <img src={pathname == "/account" ? Account_active : Account} />
                                    <div className="color6 ml-8">Account</div>
                                </Box>
                            </Link>
                            <Link component={NavLink} to="/calculator" className={`menuItem ${pathname == "/calculator" ? "menuItemActive" : ""}`} onClick={handleCloseMenu}>
                                <Box display='flex' alignItems='center' justifyContent='center' className="menuItemContainer">
                                    <img src={pathname == "/calculator" ? Calculator_active : Calculator} />
                                    <div className="color6 ml-8">Calculator</div>
                                </Box>
                            </Link>
                            <Link component={NavLink} to="/sweep" className={`menuItem ${pathname == "/sweep" ? "menuItemActive" : ""}`} onClick={handleCloseMenu}>
                                <Box display='flex' alignItems='center' justifyContent='center' className="menuItemContainer">
                                    <img src={pathname == "/sweep" ? Sweep_active : Sweep} />
                                    <div className="color6 ml-8">Sweep</div>
                                </Box>
                            </Link>
                            {
                                menuList.map((item, index) =>
                                    <Link href={item.url} target='_blank' className="menuItem" onClick={handleCloseMenu}>
                                        <Box display='flex' alignItems='center' justifyContent='center' className="menuItemContainer" key={index}>
                                            <img src={item.icon} />
                                            <div className="color6 ml-8">{item.title}</div>
                                        </Box>
                                    </Link>
                                )
                            }
                        </Box>
                        <div className="menuListTab">
                            <a href="https://www.ticketinu.com/" target='_blank' className="menuItemTab">
                                <Box display='flex' alignItems='center' justifyContent='center' className="color2">Home</Box>
                            </a>
                            <a href="https://docs.ticketinu.com/" target='_blank' className="menuItemTab mt-20">
                                <Box display='flex' alignItems='center' justifyContent='center' className="color2">Docs</Box>
                            </a>
                            <a href="https://discord.gg/GHN7ESkXxU" target='_blank' className="menuItemTab mt-20">
                                <Box display='flex' alignItems='center' justifyContent='center' className="color2">Discord</Box>
                            </a>
                        </div>
                    </Box>
                    <img className="close" src={Close} onClick={handleCloseMenu} />
                </div>
            </Dialog>
        </div>
    )
}